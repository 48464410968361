<template>
  <div id="daily-report" class="container-fluid">

    <div class="d-flex align-items-center">
      <h4 class="text-primary m-0">สรุปยอดวันที่</h4>
      <div class="pl-3 pb-1">
        <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
          <div class="input-group-prepend">
            <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
          </div>
          <flat-pickr
            :config="datepickerConfig"
            v-model="date"
            class="form-control form-control-sm"
          />
        </div>
      </div>
    </div>

    <div v-if="items.length > 0" class="card mb-3 py-3 d-none d-sm-block">
      <bar-chart :chartdata="chartCollection" :options="chartOptions"></bar-chart>
      <div class="text-center mt-4">
        <b-form-checkbox-group
          v-model="chartSelected"
          :options="chartSelectOptions"
          name="chart-select"
        ></b-form-checkbox-group>
      </div>
    </div>

    <div class="card table-responsive">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th rowspan="2" class="text-center" style="width: 10%">ทีม</th>
            <th colspan="4" class="text-center">ยอดเว็บ</th>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">เงินตั้งต้น</td>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">โอนกลับบัญชีกลาง</td>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">เหลือ</td>
            <th colspan="2" class="text-center">สรุปได้-เสีย</th>
            <td rowspan="2" class="text-center" style="vertical-align: middle;">สถานะ</td>
          </tr>
          <tr>
            <td style="width: 8%;" class="text-center">ยอดคีย์</td>
            <td style="width: 8%;" class="text-center">ยอดจ่าย</td>
            <td style="width: 8%;" class="text-center">ขาด/เกิน</td>
            <td style="width: 8%;" class="text-center">รวม</td>

            <td width="10%" class="text-center">Company</td>
            <td width="10%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td><router-link :to="{name: 'ManagerReportDateBranch', params: {date: date, accountId: item._id}}">{{ item.name }}</router-link></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.sales)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.won)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.other)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.total)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.start.amount)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.refund.amount)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.summary.grandTotal)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.companyWinloss)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.ownerWinloss)"></span></td>
            <td class="text-center" v-html="reportStatus(item.status)"></td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="11" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.other)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.total)"></span></th>
            <th colspan="3"></th>
            <th class="text-right"><span v-html="numberFormat(total.companyWinloss)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerWinloss)"></span></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import moment from '@/helpers/moment'
import ConvStatus from '@/helpers/conv-status'

import ReportService from '@/services/ReportService'
import BranchService from '@/services/branchService.js'

import BarChart from '@/views/components/BarChart'

export default {
  name: 'AdminReportDaily',
  components: {
    flatPickr,
    Loading,
    BarChart
  },
  data() {
    return {
      reportBranchs: [],
      Branchs: [],
      isLoading: false,
      date: this.$route.params?.date || moment().add(-10, 'h').format('YYYY-MM-DD'),

      datepickerConfig: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        maxDate: moment().format("YYYY-MM-DD")
      },

      chartSelected: ['totals'],
      chartSelectOptions: [
        { text: 'รวม', value: 'totals' },
        { text: 'ยอดคีย์', value: 'sales' },
        { text: 'ยอดจ่าย', value: 'wons' },
        { text: 'ขาด/เกิน', value: 'others' }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }

    }
  },
  computed: {
    items() {
      const defaultAmount = {
        commission: 0,
        company: 0,
        other: 0,
        sales: 0,
        total: 0,
        won: 0
      }

      return this.Branchs.map((branch)=>{
        const report = this.reportBranchs.find((rep)=>{
          return branch._id === rep.owners.accountId
        })

        if(report) {
          return {
            ...branch,
            status: report.status,
            amounts: {
              ...defaultAmount,
              ...report.amounts
            },
            summary: report.summary
          }
        }else{
          return {
            ...branch,
            status: null,
            amounts: defaultAmount,
            summary: {
              grandTotal: 0,
              refund: {
                amount: 0
              },
              start: {
                amount: 0
              }
            }
          }
        }
      })
    },
    total() {
      return this.items.reduce((total, d)=>{
        total.sales += d.amounts.sales
        total.won += d.amounts.won
        total.other += d.amounts.other
        total.total += d.amounts.total
        total.companyWinloss += (d.amounts?.companyWinloss || 0)
        total.ownerWinloss += (d.amounts?.ownerWinloss || 0)
        return total
      }, {
        sales: 0,
        won: 0,
        other: 0,
        total: 0,
        companyWinloss: 0,
        ownerWinloss: 0
      })
    },
    chartCollection() {

      const labels = this.items.map((item)=>{
        return item.name
      })

      let datasets = {}
      datasets.sales = {
        label: 'ยอดคีย์',
        backgroundColor: '#c3e6cb',
        data: this.items.map((item)=>{
          return item.amounts.sales || 0
        })
      }

      datasets.wons = {
        label: 'ยอดจ่าย',
        backgroundColor: '#f5c6cb',
        data: this.items.map((item)=>{
          return item.amounts.won || 0
        })
      }

      datasets.others = {
        label: 'รายรับ/รายจ่าย',
        backgroundColor: '#bee5eb',
        data: this.items.map((item)=>{
          return item.amounts.other || 0
        })
      }

      datasets.totals = {
        label: 'รวม',
        backgroundColor: '#b8daff',
        data: this.items.map((item)=>{
          return item.amounts.total || 0
        })
      }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    }
  },
  watch: {
    date(newDate, oldDate) {
      // this.$router.push({
      //   name: 'AdminReportDate',
      //   params: {
      //     date: newDate
      //   }
      // })
      this.getReport()
    }
  },
  methods: {

    getReport() {
      this.isLoading = true
      ReportService.getSummary({
        start: this.date,
        end: this.date
      })
      .then((response)=>{
        if(response.status === 'success') {
          this.reportBranchs = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },

    getBranch() {
      BranchService.gets().then((response)=>{
        if(response.status === 'success') {
          this.Branchs = response.data
        }
      })
    },

    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },

    reportStatus(code) {
      const status = ConvStatus.report(code)
      if(!status.code)
        return '-'

      return `<span class="${status.class}"><i class="${status.icon}"></i> ${status.text}</span>`
    }
  },
  mounted() {
    this.getReport()
    this.getBranch()
  }
}
</script>
<style lang="scss" scoped>
#daily-report {
  padding-bottom: 100px;
  position: relative;

  table {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}
</style>
